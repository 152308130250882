import 'slick-carousel';

export function initModuleBildergalerie(editmode) {
	
	editmode = editmode || false;
	
	var module = $('.module._bildergalerie');
	
	if( !editmode ) {

		if( module.is('.-narrow') ) {
			
			module.find('.slider.slick').slick({
				dots: false,
				arrows: true,
				infinite: false,
				useTransform: true,
				speed: 500,
				cssEase: 'linear',
				slidesToShow: 2,
				slidesToScroll: 1,
				centerMode: false,
				variableWidth: false,
				draggable: true,
				swipe: true,
				swipeToSlide: true,
				touchMove: true,
				responsive: [
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
			
		} else {
			
			module.find('.slider.slick').slick({
				dots: false,
				arrows: true,
				infinite: false,
				useTransform: true,
				speed: 500,
				cssEase: 'linear',
				slidesToShow: 3,
				slidesToScroll: 1,
				centerMode: false,
				variableWidth: false,
				draggable: true,
				swipe: true,
				swipeToSlide: true,
				touchMove: true,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		}
	}
}