import Alert from 'bootstrap/js/dist/alert'
import Button from 'bootstrap/js/dist/button'
import Carousel from 'bootstrap/js/dist/carousel'
import Collapse from 'bootstrap/js/dist/collapse'
import Dropdown from 'bootstrap/js/dist/dropdown'
import Modal from 'bootstrap/js/dist/modal'
//import Popover from 'bootstrap/js/dist/popover'
//import Scrollspy from 'bootstrap/js/dist/scrollspy'
//import Tab from 'bootstrap/js/dist/tab'
//import Toast from 'bootstrap/js/dist/toast'
//import Tooltip from 'bootstrap/js/dist/tooltip'
import Util from 'bootstrap/js/dist/util'

export {
  Util,
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  /*Popover,
  Scrollspy,
  Tab,
  Toast,
  Tooltip*/
}