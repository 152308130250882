export function initStellenangebote(editmode) {
	
	editmode = editmode || false;

	$('#stellenangebotForm').on('submit', function(e) {
		e.preventDefault();
		
		$(this).find('input[type=submit]').attr('disabled', 'disabled').prop( "disabled", true ); //prevent double click
		
		submitForm();
	});
}

function submitForm(isCallbackSubmit) {
	
	if( isCallbackSubmit !== true ) {
		enableRecaptcha(function() {
			submitForm(true); //special submit with additional parameter
		});
		return;
	}
	
	var form = $('#stellenangebotForm');
	var button = form.find('input[type=submit]');
	var modal = $('#stellenangebotModal');
	var data = new FormData(form[0]);
	data.append('is_ajax', 1);
	
	$.ajax({
		type: "POST",
		url: location.href,
		data: data,
		dataType: 'json',
		enctype: 'multipart/form-data',
		processData: false,
		contentType: false,
		cache: false,
		success: function(data) {
			console.log(data);
			
			$('.modal-body > p', modal).html(data.message);
			
			
			if( data.success !== true ) {
				
				$.each(data.errors, function(key, value) {
					$('[name='+key+']').siblings('.invalid-feedback').html(value);
				});
				
				form.addClass('was-validated');
				
				button.attr('disabled', null).prop( "disabled", false );
				
			} else {
				$('#stellenangebotModal').modal('show');	
				button.attr('disabled', 'disabled').prop( "disabled", true );
			}
			
			
		}
	}).fail(function() {
		alert( "Leider ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal." );
		button.attr('disabled', null).prop( "disabled", false );
	});
}