import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js';

export function initDatepicker() {
	
	$('.datepicker').each(function () { //each ist Pflicht; ohne each wirft die Lib einen Fehler
		$(this).datepicker({
			format: "dd.mm.yyyy",
			maxViewMode: 2,
			language: "de",
			autoclose: true,
			todayHighlight: true
		})/*.on('changeDate', function() {
			$(this).closest('form').submit();
		})*/;
	});
}