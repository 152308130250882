export function initMobileNavigation() {

	$('.navbar-toggler').on('click', function (e) {
		e.preventDefault();

		$('#offcanvas-menu').toggleClass('active');
	});

	var menuInitHeight = $('#offcanvas-menu nav.menu').height(),
		hasChildToggle = $('#offcanvas-menu .has-children .submenu-toggle'),
		backBtn = $('#offcanvas-menu .menu-submenu .back-btn');

	backBtn.on('click', function (e) {
		var self = this,
			submenu = $(self).parent('.menu-submenu'),
			parentSubmenu = $(self).closest('.off-view'),
			menu = $(self).closest('nav.menu');

		submenu.removeClass('in-view');
		parentSubmenu.removeClass('off-view');
		if (parentSubmenu.is('nav.menu')) {
			menu.css('height', menuInitHeight);
		} else {
			menu.css('height', parentSubmenu.height());
		}

		e.preventDefault();
	});

	hasChildToggle.on('click', function (e) {
		var self = this,
			submenu = $(self).closest('.has-children').find('> .menu-submenu'),
			parentSubmenu = $(self).closest('.menu-submenu, nav.menu'), //parent menu (nicht das ausgewählte)
			menu = $(self).closest('nav.menu');//oberste ebene

		parentSubmenu.addClass('off-view');
		submenu.addClass('in-view');//ziel menu öffnen
		menu.css('height', submenu.height());

		e.preventDefault();
		return false;
	});
}