import 'slick-carousel';

export function initModuleAktuelles(editmode) {
	
	editmode = editmode || false;
	
	if( !editmode ) {

		$('.module._aktuelles .slider.slick').slick({
			dots: true,
			arrows: false,
			infinite: false,
			useTransform: true,
			speed: 500,
			cssEase: 'linear',
			slidesToShow: 2,
			slidesToScroll: 1,
			centerMode: false,
			variableWidth: false,
			draggable: true,
			swipe: true,
			swipeToSlide: true,
			touchMove: true,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	}
}