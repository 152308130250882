import Cookies from 'js-cookie';

export function initCookieBanner() {
	
	//banner
	$('.cookie-banner').find('button[type=submit]').on('click', function (e) {
		
		$(this).closest('.cookie-banner').hide();
		
		if( $(this).is('.reject') ) {
			e.preventDefault();
			Cookies.set('GDPRp', '', { expires: 730 });
		}
	});
	
	//wenn die cookies bereits gesetzt wurden, der Browsercache aber noch das Banner liefert, dieses verstecken
	if( Cookies.get('GDPRp') || Cookies.get('GDPRs') ) {
		$('.cookie-banner').hide();
	}

	//einstellungs-seite
	$('.cookie-buttons').find('#accept_settings').hide(); //wenn js aktiviert ist, läuft das Speichern onchange

	$('.cookie').find('input[type=radio]').on('change', function () {
		var persistent = $(".cookie-box :enabled:checked[value='on']")
			.map(function () {
				return this.name
			})
			.get()
			.sort()
			.join("|");

		if (persistent) {
			Cookies.set('GDPRp', persistent, { expires: 730 });
		} else {
			Cookies.remove('GDPRp');
		}

		var session = $(".cookie-box :checked[value='session']")
			.map(function () {
				return this.name
			})
			.get()
			.sort()
			.join("|");

		if (session) {
			Cookies.set('GDPRs', session);
		} else {
			Cookies.remove('GDPRs');
		}
	});
	
	//video oder karte aktivieren
	$('.no-consent').each(function() {
		$(this).find('button').on('click', function () {
			var cookie = $(this).is('.session') ? 'GDPRs' : 'GDPRp', //session oder persistenter cookie
				current = Cookies.get(cookie),
				service = $(this).data('service');
			if( cookie == 'GDPRp' ) {
				Cookies.set(cookie, current ? current + '|' + service : service, {expires: 730});
			} else {
				Cookies.set(cookie, current ? current + '|' + service : service);
			}
			$(this).prop('disabled', true);
			document.location.reload();
		});
	});
}