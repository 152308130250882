import "leaflet";
//import "../TileLayer.Grayscale.js";

export function initModuleKarte(editmode) {
	
	$('.map').each(function() {
		var map = $(this),
			wrapper = map.next('.editmode-address');
		
		showMap(map.attr('id'), editmode);
		
		if( editmode ) {
			
			wrapper.find('button').on('click', function() {
				var street = $('.pimcore_tag_input[data-real-name=strasse]', wrapper),
					no = $('.pimcore_tag_input[data-real-name=nr]', wrapper),
					zip = $('.pimcore_tag_input[data-real-name=plz]', wrapper),
					city = $('.pimcore_tag_input[data-real-name=ort]', wrapper),
					lat = $('.pimcore_tag_input[data-real-name=lat]', wrapper),
					lon = $('.pimcore_tag_input[data-real-name=lon]', wrapper);
				
				$.getJSON('https://nominatim.openstreetmap.org/search', {
					format: 'json',
					street: no.text()+' '+street.text(),
					city: city.text(),
					country: 'de',
					postalcode: zip.text()
				}, function(data) {
					
					if( $.isArray(data) && data.length >= 1 ) {
						
						lat.text(data[0].lat.toString().substring(0, 12));
						lon.text(data[0].lon.toString().substring(0, 12));
						
						window.marker[map.attr('id')].setLatLng([data[0].lat, data[0].lon]);
						window.map[map.attr('id')].panTo([data[0].lat, data[0].lon]);
						
					} else {
						alert('Die Adresse konnte nicht ermittelt werden!');
					}
				});
			});
		}
	});
}


function showMap(id, editmode) {
	L.Icon.Default.imagePath = '/img/';
	
	var customIcon = L.icon({
		iconUrl: '/img/icon-position.png',
		//shadowUrl: 'spacer.png',

		iconSize:     [49, 49], // size of the icon
		//shadowSize:   [1, 1], // size of the shadow
		iconAnchor:   [25, 49], // point of the icon which will correspond to marker's location
		//shadowAnchor: [1, 1],  // the same for the shadow
		popupAnchor:  [0, -49] // point from which the popup should open relative to the iconAnchor
	});
	
	window.map[id] = L.map(id, {
		center: window.position[id],
		zoom: 15
	});
	
	window.map[id].scrollWheelZoom.disable();
	
	L.tileLayer/*.grayscale*/('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		attribution: '© <a href="http://www.openstreetmap.org/copyright/" rel="external" target="_blank">OpenStreetMap</a>-Mitwirkende.',
		maxZoom: 19
	}).addTo(window.map[id]);
	
	if( window.position[id] ) {
		
		window.marker[id] = new L.marker(window.position[id], {icon: customIcon}).addTo(window.map[id]);
		
		if( !editmode && window.popup[id] ) {
			window.marker[id].bindPopup(
				'<h4 class="mb-2">'+window.popup[id]['title']+'</h4>'+
				'<p class="address">'+window.popup[id]['street']+' '+window.popup[id]['no']+'<br>'+window.popup[id]['zip']+' '+window.popup[id]['city']+'</p>'
			).openPopup();
		}
	}
}