import bsCustomFileInput from 'bs-custom-file-input';

export function initForms(editmode) {
	
	editmode = editmode || false;
	
	if( !editmode ) {
		bsCustomFileInput.init();
	}
	
	$('.float-label input').on( "focus", function() {
		$(this).closest('.form-group').find('label').addClass('active');
	}).on( "blur", function() {
		if( $(this).val() == "" ) {
			$(this).closest('.form-group').find('label').removeClass('active');
		}
	}).on( "change", function() {
		if( $(this).val() == "" ) {
			$(this).closest('.form-group').find('label').removeClass('active');
		} else {
			$(this).closest('.form-group').find('label').addClass('active');
		}
	});
	
	//wenn ein form-element nicht leer ist, das float-label aktivieren
	$('.float-label').find('input, select').each( function() {
		if( $(this).val() != "" ) {
			$(this).closest('.form-group').find('label').addClass('active');
		}
	});
	
	$('.custom-select[data-submit=true]').on('change', function() {
		$(this).closest('form').submit();
	});
}