import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';

export function initPhotoSwipe(context) {
	
	context = context || $('body');

	var initPhotoSwipeFromDOM = function(gallerySelector, context) {
		
		if( $('.pswp').length === 0 ) {
			$('body').append(
				`<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
					<div class="pswp__bg"></div>
					<div class="pswp__scroll-wrap">
					<div class="pswp__container">
						<div class="pswp__item"></div>
						<div class="pswp__item"></div>
						<div class="pswp__item"></div>
					</div>
					<div class="pswp__ui pswp__ui--hidden">
						<div class="pswp__top-bar">
							<div class="pswp__counter"></div>
							<button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
							<button class="pswp__button pswp__button--share" title="Share"></button>
							<button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
							<div class="pswp__preloader">
								<div class="pswp__preloader__icn">
								  <div class="pswp__preloader__cut">
									<div class="pswp__preloader__donut"></div>
								  </div>
								</div>
							</div>
						</div>
						<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
							<div class="pswp__share-tooltip"></div> 
						</div>
						<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
						</button>
						<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
						</button>
						<div class="pswp__caption">
							<div class="pswp__caption__center"></div>
						</div>
					  </div>
					</div>
				</div>`
			);
		}
		
	    var parseThumbnailElements = function(gallery) {
	        var galleryItems = gallery.find('.lightbox'),
				items = [];

	        galleryItems.each(function() {
				var galleryItem = $(this),
					img = galleryItem.find('img'),
					item,
					size,
					src;
				
	            if ( !galleryItem.is('[data-size]') ) {
					size = galleryItem.find('[data-size]').data('size').split('x');
				} else {
					size = galleryItem.data('size').split('x');
				}
				
				if ( !galleryItem.is('a') ) {
					src = galleryItem.find('a').attr('href');
				} else {
					src = galleryItem.attr('href');
				}

	            // create slide object
	            item = {
	                src: src,
	                w: parseInt(size[0], 10),
	                h: parseInt(size[1], 10)
	            };

	            if( galleryItem.is('figure') && galleryItem.find('figcaption').length > 0 ) {
	                item.title = galleryItem.find('figcaption').html(); 
	            }

	            if(img.length > 0) {
	                item.msrc = img.attr('src');
	            } 

	            item.el = galleryItem[0]; // save link to element for getThumbBoundsFn
	            items.push(item);
	        });

	        return items;
	    };

	    var onThumbnailsClick = function(e) {
	        e.preventDefault();

	        var that = $(this),
				gallery = that.closest(gallerySelector),
				galleryIndex = 0;

	        if( !!gallery.attr('data-pswp-uid') && gallery.find('.lightbox a[data-size], .lightbox[data-size]').length > 0 ) {
				
				//welches item wurde geklickt?
				galleryIndex = gallery.find('.lightbox').index(that);
				
	            // open PhotoSwipe if valid index found
	            openPhotoSwipe( galleryIndex, gallery );
	        }
	    };

	    var openPhotoSwipe = function(galleryIndex, gallery, disableAnimation) {
	        var pswpElement = $('.pswp'),
	            options,
	            items;

	        items = parseThumbnailElements(gallery);

	        options = {
				shareEl: false,
				/*shareButtons: [
					{id:'download', label:'Download', url:'{{raw_image_url}}', download:true}
				],*/
				history: false,
				showHideOpacity: true,
				hideAnimationDuration: 250, 
				showAnimationDuration: 250,
	            // define gallery index (for URL)
	            galleryUID: gallery.attr('data-pswp-uid'),
				index: galleryIndex,

	            getThumbBoundsFn: function(index) {
	                // See Options -> getThumbBoundsFn section of documentation for more info
	                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
	                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
	                    rect = thumbnail.getBoundingClientRect(); 

	                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
	            }
	        };

	        // exit if index not found
	        if( isNaN(options.index) ) {
	            options.index = 0;
	        }

	        if(disableAnimation) {
	            options.showAnimationDuration = 0;
	        }

	        // Pass data to PhotoSwipe and initialize it
	        var ps = new PhotoSwipe( pswpElement[0], PhotoSwipeUIDefault, items, options);
			ps.init();
	    };

	    // loop through all gallery elements and bind events
	    var galleryElements = context.find( gallerySelector );

	    galleryElements.each(function(i) {
			var gallery = $(this),
				galleryItem = gallery.find('.lightbox');
				
			gallery.attr({
				'data-pswp-uid': i+1,
				itemscope: '',
				itemtype: 'http://schema.org/ImageGallery'
			});
			
			if (galleryItem.length > 0) {
				galleryItem.on('click', onThumbnailsClick);
			}
		});
	};

	// execute above function
	if (context.find('.lightbox-gallery, .wysiwyg, .module._bild, .module._bildergalerie').length > 0) {
		initPhotoSwipeFromDOM('.lightbox-gallery, .wysiwyg, .module._bild, .module._bildergalerie', context);
	}
}