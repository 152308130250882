export function initScrollTop() {

	$('#scrollTop').on('click', function(e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: 0
		},'slow');
	});

	window.onscroll = function() {
		if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
			document.getElementById('scrollTop').style.display = "block";
		} else {
			document.getElementById('scrollTop').style.display = "none";
		}
	};
}