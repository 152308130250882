import {initModuleTeasercarousel} from './modules/teasercarousel.js';
import {initModuleAktuelles} from './modules/aktuelles.js';
import {initModuleKarte} from './modules/karte.js';
import {initModuleBildergalerie} from './modules/bildergalerie.js';

export function initModules(editmode) {
	
	editmode = editmode || false;
	
	initModuleTeasercarousel(editmode);
	initModuleAktuelles(editmode);
	initModuleKarte(editmode);
	initModuleBildergalerie(editmode);
}