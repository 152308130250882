window.loadRecaptcha = function(enable, callback, options) {
	window.enableRecaptchaWithCallback = function() {
		enableRecaptcha(callback, options);
	};

	if(!document.getElementById('recaptcha-script')) {

		enable = enable || false;
		var scriptTag = document.createElement('script');
		scriptTag.id = 'recaptcha-script';
		scriptTag.src = 'https://www.google.com/recaptcha/api.js?render='+window.recaptcha_public_key+(enable ? (typeof callback === "undefined" ? '&onload=enableRecaptcha' : '&onload=enableRecaptchaWithCallback') : '');
		var head = document.getElementsByTagName('head')[0];
		head.appendChild(scriptTag);

	} else if(typeof callback !== "undefined") {

		callback(options);
	}
};

window.enableRecaptcha = function(callback, options) {
	if( window.grecaptcha === undefined ) {
		loadRecaptcha(true, callback, options);
	} else {
		grecaptcha.ready(function() {
			grecaptcha.execute(window.recaptcha_public_key, {action: 'homepage'}).then(function(token) {
				var tokens = document.getElementsByName('recaptcha_token');
				for (var i = 0; i < tokens.length; i++) {
					tokens[i].value = token;
				}
				if( typeof callback === 'function' ) {
					callback(options);
				}
			});
		});
	}
};