import Url from 'domurl';
import 'jquery-typeahead';

/*$.fn.selectpicker.defaults = {
    noneSelectedText: 'Bitte wählen...',
    noneResultsText: 'Keine Ergebnisse für {0}',
    countSelectedText: function (numSelected, numTotal) {
      return (numSelected == 1) ? '{0} Element ausgewählt' : '{0} Elemente ausgewählt';
    },
    maxOptionsText: function (numAll, numGroup) {
      return [
        (numAll == 1) ? 'Limit erreicht ({n} Element max.)' : 'Limit erreicht ({n} Elemente max.)',
        (numGroup == 1) ? 'Gruppen-Limit erreicht ({n} Element max.)' : 'Gruppen-Limit erreicht ({n} Elemente max.)'
      ];
    },
    selectAllText: 'Alles auswählen',
    deselectAllText: 'Nichts auswählen',
    multipleSeparator: ', ',
	style: 'btn-light'
};*/

export function initSearch() {
	
	/*$('.selectpicker').selectpicker({
		//style: 'light'
	});
	$('.selectpicker[data-submit=true]').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
		$(this).closest('form').submit();
	});*/

	$('#sort').on('change', function () {
		var url = new Url;
		url.query.sort = $(this).val();
		window.location.href = url.toString();
	});
	
	if( Modernizr.mq('(min-width: 992px)') ) {
		//suggest für die volltextsuche
		$('#search').typeahead({
			minLength: 3,
			order: "asc",
			dynamic: true,
			delay: 500,
			maxItem: false, //wird über solr geregelt
			selector: {
				cancelButton: "d-none"
			},
			display: 'value',
			template: function (query, item) {
				return '<div class="small">{{category}}</div>{{value}}';
			},
			source: {
				suggest: {
					href: function (item) {
						return item.url;
					},
					ajax: {
						type: "GET",
						url: "/suggest",
						data: {
							q: "{{query}}",
							lang: lang
						}
					}
				}
			},
			callback: {
				onPopulateSource: function (node, data, group, path) {
					if( $.isArray(data) && data.length > 0 && !!data[data.length-1].fulltext ) {
						window.fulltext = data.pop().fulltext;
					} else {
						window.fulltext = false;
					}
					return data;
				},
				onLayoutBuiltBefore: function (node, query, result, resultHtmlList) {
					if (query === "") return;

					if (result.length > 0 && window.fulltext != false) {
						resultHtmlList.append('<li class="typeahead__item typeahead__group-fulltext">'+window.fulltext+'</li>');
					}

					return resultHtmlList;
				}
			}
		});
	}
}