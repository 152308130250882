import './bootstrap.js';
import { initCookieBanner } from '../../src/Bit/CookieBundle/Resources/assets/js/cookies.js';
import Headroom from 'headroom.js';
import picturefill from 'picturefill'; //fix for IE with picture tag
import { initSearch } from './search.js';
import { initDatepicker } from './datepicker.js';
import { initMobileNavigation } from './offcanvas.js';
import { initScrollTop } from './scrolltop.js';
import { initModules } from './modules.js';
import { initForms } from './forms.js';
import './recaptcha.js';
import { initStellenangebote } from './stellenangebote.js';
import { initPhotoSwipe } from './photoSwipe.js';
//import { initBsMultiselect } from './bs-multiselect.js';
import { initSelectpicker } from './bs-select.js';

//alles was man inline in html verwenden will, kommt hier hin
window.jQuery = window.$ = $;

function onReady(editmode) {

	if (Modernizr.mq('(max-width: 991px)')) {
		initMobileNavigation();
	}

	picturefill(); //fix for IE with picture tag

	//scrolling affix für header
	if (Headroom.cutsTheMustard) {
		if (Modernizr.mq('(min-width: 992px)')) {
			if ($("#offcanvas-menu").length == 1 && $('#header').length == 1) {
				new Headroom(document.querySelector("#offcanvas-menu"), { offset: $('#header').height() + 15 }).init();
			}
		} else {
			if ($('#header').length == 1) {
				new Headroom(document.querySelector("#header"), {}).init();
			}
		}
	}

	initModules(editmode);

	initCookieBanner();

	initForms(editmode);
	initDatepicker();
	initSearch();

	initStellenangebote(editmode);

	if (!editmode) {
		initPhotoSwipe();
	}

	$('.size-dialog').on('click', function (e) {
		e.preventDefault();

		$('#sizeModal').modal('show');
	});

	initScrollTop();

	//initBsMultiselect();
	initSelectpicker();


	//$('select.bs-multiselect').multiselect();
}

$(document).ready(function () {
	var editmode = $('html').data('editmode') === true;
	onReady(editmode);

	if ($('h1').length > 0) {
		$('#readspeaker_button1').insertBefore($('h1').first());
	} else {
		debugger;
		if ($('.headline').length > 0) {
			// auf der startseite? Dann bitte button nirgendwo anzeigen
			document.getElementById('readspeaker_button1').style.display = "none";
		}
	}

});

function loadSnippet(url, target, params, onSuccess, onFail) {
	$.ajax(url, {
		method: "GET",
		data: params,
		context: document.body,
		async: true
	}).done(function (response) {
		$('#' + target).html(response);
		if (onSuccess) {
			onSuccess();
		}
	})
		.fail(function () {
			if (onFail) {
				onFail();
			}
		})
		.always(function () {
			//
		});
}

window.updateStellen = function (form, documentUrl){

	var snippetBaseUrl = '/schnipsel/deutsch/stellenangebote';
	var $form = $(form);
	var params = $form.serialize();

	console.log(params);

	loadSnippet(snippetBaseUrl, 'stellenangebote-list-snippet', params, function() {
		changeUrl("Stellenangebote", documentUrl + "?" + params);
	});

}

window.changeUrl = function(page, url) {
	if (typeof (history.pushState) != "undefined") {
		var obj = { Page: page, Url: url };
		history.pushState(obj, obj.Page, obj.Url);
	} else {
		console.log("Browser does not support HTML5.");
	}
}
